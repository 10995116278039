const auditsRoutes = {
	name: 'audits',
	path: '/audits',
	redirect: { name: 'audits.audits' },
	component: () => import('@/modules/audits/AuditsModule.vue'),
	meta: {
		requiresAuth: true,
		permissions: [ 'audits' ]
	},
	children: [
		{
			name: 'audits.audits',
			path: 'audits',
			redirect: { name: 'audits.audits.index' },
			children: [
				{
					name: 'audits.audits.index',
					path: '',
					component: () => import('@/modules/audits/pages/AuditsIndex.vue')
				},
				{
					name: 'audits.audits.create',
					path: 'create',
					props: { isNew: true, isEdit: true },
					component: () => import('@/modules/audits/pages/AuditPage.vue'),
					meta: { permissions: [ 'audits.audits.audits.create' ] },
				},
				{
					name: 'audits.audits.show',
					path: ':auditId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/audits/pages/AuditPage.vue'),
					meta: { permissions: [ 'audits.audits.audits.show' ] },
				},
				{
					name: 'audits.audits.edit',
					path: ':auditId(\\d+)/edit',
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/audits/pages/AuditPage.vue'),
					meta: { permissions: [ 'audits.audits.audits.edit' ] },
				},
				{
					name: 'audits.audits.files.show',
					path: ':auditId(\\d+)/files/:fileId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/audits/pages/AuditFilePage.vue'),
				},
				{
					name: 'audits.audits.files.create',
					path: ':auditId(\\d+)/files/create',
					props: { isNew: true, isEdit: true },
					component: () => import('@/modules/audits/pages/AuditFilePage.vue')
				},
				{
					name: 'audits.audits.files.edit',
					path: ':auditId(\\d+)/files/:fileId(\\d+)/edit',
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/audits/pages/AuditFilePage.vue')
				}

			]
		},
		{
			name: 'audits.relations',
			path: 'relations',
			component: () => import('@/modules/audits/pages/RelationsIndex.vue')
		},
		{
			name: 'audits.norms',
			path: 'norms',
			component: () => import('@/modules/audits/pages/NormsIndex.vue')
		},
		{
			name: 'audits.deviations',
			path: 'deviations',
			component: () => import('@/modules/audits/pages/DeviationsIndex.vue')
		},
		{
			name: 'audits.actions',
			path: 'actions',
			component: () => import('@/modules/audits/pages/ActionsIndex.vue')
		},
		{
			name: 'audits.planning',
			path: 'planning',
			component: () => import('@/modules/audits/pages/PlanningPage.vue')
		},
		{
			name: 'audits.management',
			path: 'management',
			component: () => import('@/modules/audits/pages/ManagementPage.vue')
		}
	]
}

export default auditsRoutes
